/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.bundle.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/aos@2.3.4/dist/aos.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
